import React from "react";
import { CardHeader } from "reactstrap";
import './Header.css';

const Header = ({ electionName }) => {
  return (
    <CardHeader className="text-center">
      <h1>Election Monkey</h1>
      <h4>{electionName}</h4>
    </CardHeader>
  )
}

export default Header;
