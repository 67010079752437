import React from 'react';
import {Card, CardBody, CardHeader, CardFooter, Col, Row} from "reactstrap";
import Footer from "../Footer/Footer";
import './MissingElectionId.css';

const MissingElectionId = () => {

  return (
    <Card>
      <CardHeader className="text-center">
        <h1>Election Monkey</h1>
        <h4>Voter login</h4>
      </CardHeader>
      <CardBody className="text-center">
        <Row>
          <Col md={12}>
            <h4>Missing election ID in URL</h4>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default MissingElectionId;
