/**
 * Call Firebase cloud function to authenticate user using password
 * or Google OAuth2
 * @param path    LOGIN_ADMIN_WITH_PASSWORD | LOGIN_ADMIN_WITH_GOOGLE
 * @param token   JWT when logging in with google
 * @param body    {id, password} when logging in with an ID and password
 * @returns {Promise<unknown>} See Firebase function doc header for details
 *                             about return object
 * @private
 */
const _fetch = async (path, token, role, body) => {
  const rootUrl = document.location.hostname === ('localhost') ?
    'http://localhost:5001/onlineballot/us-central1' :
    'https://us-central1-onlineballot.cloudfunctions.net';
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  body = {...body, token, role}
  return new Promise((resolve, reject) => {
    fetch(rootUrl + path, {
      method: 'POST',
      body: body ? JSON.stringify(body) : '{}',
      headers,
      // mode: 'cors'
    })
      .then(res => {
        return res.json()
      })
      .then(jsonData => {
        resolve(jsonData);
      })
      .catch(err => {
        reject(err);
      })
  });
}

export default _fetch
