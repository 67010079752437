import React, {useEffect, useMemo, useState} from 'react';
import { useDispatch } from 'react-redux';
import actionStrings from "../../reducer/actionStrings";
import actionCreator from "../../reducer/actionCreator";

import firebase from "firebase/app";
import 'firebase/auth';
import loginAuthFlow from "../../utility/loginAuthFlow";
import {LOGIN_VOTER_WITH_GOOGLE, LOGIN_VOTER_WITH_PASSWORD} from "../../utility/paths";
import {decode} from "jsonwebtoken";
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {GoogleLogin} from "react-google-login";
import buttonImg from "../../assets/btn_google_signin_dark_normal_web@2x.png";

import 'firebase/auth';
import './Login.css';

const CLIENT_ID = '182261387984-0kc95otit7flu6ja7plqjvc2jflnh8au.apps.googleusercontent.com';

const Login = ({election}) => {

  const ERROR_MSG_VISIBLE_DELAY = 8000;
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    firebase.auth().signOut();
  }, [])

  const dispatch = useDispatch();

  const useEnableLoginButton = (id, password, isLoggingIn) => {
    return useMemo(() => {
      return id.length > 0 && password.length > 0 && !isLoggingIn
    }, [id, password, isLoggingIn])
  }
  const enableLoginButton = useEnableLoginButton(id, password, isLoggingIn);

  const onChange = evt => {
    let field = evt.currentTarget.getAttribute('id')
    let value = evt.currentTarget.value;
    if (field === 'id') {
      setId(value)
    } else if (field === 'password') {
      setPassword(value);
    }
  }

  const clearLoginError = () => {
    setLoginError(null);
  }

  const loginWithPasswordClick = async () => {
    setIsLoggingIn(true);
    try {
      let fbTokenObj = await loginAuthFlow(LOGIN_VOTER_WITH_PASSWORD, null, 'voter', {id, password, electionId: election?.key})
      await finishLoginFlow(fbTokenObj, 'password');
    } catch (e) {
      setIsLoggingIn(false);
      setLoginError('serverError');
      setTimeout(clearLoginError, ERROR_MSG_VISIBLE_DELAY);
    }
  }

  const loginGoogleSuccess = async response => {
    setIsLoggingIn(true);
    try {
      let fbTokenObj = await loginAuthFlow(LOGIN_VOTER_WITH_GOOGLE, response.tokenId, 'voter', {electionId: election?.key})
        .catch(err => {
          setLoginError('serverError');
        })
      await finishLoginFlow(fbTokenObj, 'google');
    } catch (e) {
      setIsLoggingIn(false);
      setLoginError(e.code);
      setTimeout(clearLoginError, ERROR_MSG_VISIBLE_DELAY);
    }
  }

  const finishLoginFlow = async (fbTokenObj, loginMethod) => {
    if (fbTokenObj.err) {
      setLoginError(fbTokenObj.code);
      setIsLoggingIn(false);
      setTimeout(clearLoginError, ERROR_MSG_VISIBLE_DELAY);
      return null;
    }
    // await firebase.auth().signInWithCustomToken(fbTokenObj.token)
    let userInfo = decode(fbTokenObj.token);
    userInfo.loginMethod = loginMethod;
    // let idToken = await firebase.auth().currentUser.getIdToken(true);
    dispatch(actionCreator(actionStrings.LOGIN_SUCCESSFUL, {
      userInfo,
      token: fbTokenObj.token
    }));
  }

  const loginGoogleFailure = response => {
    console.log('Google login failure');
  }

  return (
    <Card>
      <CardHeader className="text-center">
        <h1>Election Monkey</h1>
        <h4>Voter login</h4>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={{size: 4, offset: 4}}>
            <h4 className="text-center">{election?.name}</h4>
            <h6 className="text-center">{election.voterLoginInstructions}</h6>
              {
                election?.authType === 'google' ?
                  <Row className="text-center">
                    <Col className="text-center" md={12}>
                      <GoogleLogin
                        clientId={CLIENT_ID}
                        buttonText="Login with Google"
                        style={{}}
                        tag="div"
                        type=""
                        onSuccess={loginGoogleSuccess}
                        onFailure={loginGoogleFailure}
                      >
                        <img src={buttonImg}
                             className="login-button"
                             data-provider="Google"
                             alt="Login with Google"/>
                      </GoogleLogin>
                    </Col>
                    <Col md={12}>
                      {isLoggingIn ? <span className="login-label">Logging you in now...</span> : null}
                      {loginError === 'auth/invalid-custom-token' ? <span className="login-label login-error">Internet error</span> : null}
                      {loginError === 'loginVoterWithPassword/userNotFound' ? <span className="login-label login-error">Voter not found or not eligible</span> : null}
                      {loginError === 'loginVoterWithPassword/invalidPassword' ? <span className="login-label login-error">Email and password do not match</span> : null}
                      {loginError === 'loginVoterWithGoogle/userNotFound' ? <span className="login-label login-error">Voter not found or not eligible</span> : null}
                      {loginError === 'loginVoterWithGoogle/alreadyVoted' ? <span className="login-label login-error">You already voted!</span> : null}
                      {loginError === 'invalidRole' ? <span className="login-label login-error">You must be an administrator</span> : null}
                      {loginError === 'serverError' ? <span className="login-label login-error">Internet error</span> : null}
                    </Col>
                  </Row> :
                  <Form>
                    <FormGroup>
                      <Label className="login-label-bold" for="id">User ID:</Label>
                      <Input type="id"
                             name="id"
                             id="id"
                             placeholder="User ID"
                             onChange={onChange}
                             value={id} />
                    </FormGroup>
                    <FormGroup>
                      <Label className="login-label-bold" for="password">Password:</Label>
                      <Input type="password"
                             name="password"
                             id="password"
                             placeholder="Password"
                             onChange={onChange}
                             value={password}/>
                    </FormGroup>
                    <FormGroup>
                      <Button className="login-button" color="primary" disabled={!enableLoginButton} onClick={loginWithPasswordClick}>{isLoggingIn ? 'Logging in...' : 'Login with password'}</Button>
                      {isLoggingIn ? <span className="login-label">Logging you in now...</span> : null}
                      {loginError === 'auth/invalid-custom-token' ? <span className="login-label error">Internet error</span> : null}
                      {loginError === 'userOrVoterNotFound' ? <span className="login-label error">User not found</span> : null}
                      {loginError === 'invalidPassword' ? <span className="login-label error">Email and password do not match</span> : null}
                      {loginError === 'invalidRole' ? <span className="login-label error">You must be an administrator</span> : null}
                      {loginError === 'serverError' ? <span className="login-label login-error">Internet error</span> : null}
                      {loginError === 'loginVoterWithPassword/alreadyVoted' ? <span className="login-label login-error">You have already voted in this election</span> : null}
                    </FormGroup>
                  </Form>
              }
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Login;
