import React from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import './InvalidTime.css';

const InvalidTime = ({ name, startTime, endTime, tooSoon, tooLate }) => {

  return (
    <Card>
      <CardHeader className="text-center">
        <h1>Election Monkey</h1>
        <h4>Voter login</h4>
      </CardHeader>
      <CardBody className="text-center">
        <Row>
          <Col md={12}>
            <h4>{name}</h4>
            <h6><span className="election-time-label">Open</span>: {startTime}</h6>
            <h6><span className="election-time-label">Close</span>: {endTime}</h6>
            { tooSoon && <h6>This election has not started yet</h6> }
            { tooLate && <h6>This election has already been completed</h6> }
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default InvalidTime;
