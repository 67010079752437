import pkg from '../../../package.json';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      Version {pkg.version}<br/>
      Copyright {pkg.copyright}
    </div>
  )
}

export default Footer;
