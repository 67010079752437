const actionStrings = {
  LOGIN_SUCCESSFUL: 'loginSuccessful',
  INVALID_LOGIN_FLOW: 'invalidLoginFlow',
  SET_WRONG_ROLE: 'setWrongRole',

  USER_SELECTED: 'userSelected',
  CLEAR_USER_SELECTED: 'clearUserSelected',

  SET_ELECTION_LIST: 'setElectionList',
  ELECTION_SELECTED: 'electionSelected',
  CLEAR_ELECTION_SELECTED: 'clearElectionSelected',
  VOTE_COMPLETE: 'voteComplete'
}

export default actionStrings;
