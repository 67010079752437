import {Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { produce } from 'immer';
import castVote from "../../utility/castVote";
import getCandidates from "../../utility/getCandidates";
import BallotCard from "./BallotCard";
import as from '../../reducer/actionStrings';
import actionCreator from '../../reducer/actionCreator';
import './Ballot.css';
import BallotInfoMessage from "./BallotInfoMessage";

const Ballot = ({ election }) => {

  const [error, setError] = useState(false);
  const [slate, setSlate] = useState(null);
  const [voteError, setVoteError] = useState(false);
  const [noVotes, setNoVotes] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processingError, setProcessingError] = useState(false);
  const [processingErrorMsg, setProcessingErrorMsg] = useState('');

  const token = useSelector(state => state.token);
  const userInfo = useSelector(state => state.userInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    getCandidates(token, {electionId: election.key})
      .then(response => {
        if (response.err) {
          setError(true);
        } else {
          setError(false);
          let slate = {};
          slate.offices = response.offices.map(office => {
            office.tooMany = false;
            office.candidates = office.candidates.map(candidate => {
              candidate.state = false;
              return candidate;
            })
            return office
          })
          setSlate(slate);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [election?.electionId, election?.token]);

  useEffect(() => {
    if (!slate) return;
    let voteError = slate.offices.reduce((acc, office) => {
      return acc || office.tooMany;
    }, false)
    setVoteError(voteError);
  }, [slate]);

  useEffect(() => {
    if (!slate) return;
    let candidateKeys = slate.offices.reduce((acc, office) => {
      return office.candidates.reduce((acc, candidate) => {
        if (candidate.state) {
          acc.push(candidate.key)
        }
        return acc;
      }, acc)
    }, []);
    setNoVotes(candidateKeys.length === 0);
  }, [slate])

  const onClick = evt => {
    let candidateKey = evt.currentTarget.getAttribute('data-candidate-key');
    let officeKey = evt.currentTarget.getAttribute('data-office-key');
    let $slate = produce(slate, draft => {
      let officeIdx = draft.offices.findIndex(office => office.key === officeKey);
      let candidateIdx = draft.offices[officeIdx].candidates.findIndex(candidate => candidate.key === candidateKey);
      draft.offices[officeIdx].candidates[candidateIdx].state = !draft.offices[officeIdx].candidates[candidateIdx].state;
      draft.offices[officeIdx].tooMany = draft.offices[officeIdx].candidates.reduce((acc, candidate) => acc + (candidate.state ? 1 : 0), 0) > draft.offices[officeIdx].voteForNumber;
    })
    setSlate($slate);
  }

  const castVoteClick = () => {
    setConfirmModal(false);
    setProcessing(true);
    let candidateObjs = slate.offices.reduce((acc, office) => {
      return office.candidates.reduce((acc, candidate) => {
        if (candidate.state) {
          acc.push({officeKey: office.key, candidateKey: candidate.key})
        }
        return acc;
      }, acc)
    }, []);
    castVote(token, {candidateObjs, electionId: election.key, voterId: userInfo.key})
      .then(response => {
        if (!response.err) {
          dispatch(actionCreator(as.VOTE_COMPLETE));
        } else {
          setProcessing(false);
          setProcessingError(true);
          setProcessingErrorMsg(response.code);
        }
      })
      .catch(err => {
        setProcessing(false);
        setProcessingError(true);
        setProcessingErrorMsg(err.code);
      });
  }

  const closeConfirm = () => {
    setConfirmModal(!confirmModal);
  }

  if (!slate) return <BallotInfoMessage message={"Loading..."} />;
  if (processing) return <BallotInfoMessage message={"Processing your votes"} />;
  if (error) return <BallotInfoMessage message={"Error retrieving the ballot"} />;
  if (processingError) return <BallotInfoMessage message={"Voting error ("+processingErrorMsg+")"} />;
  if (slate.offices.length === 0) return <BallotInfoMessage message={"You are not eligible to vote in this election"} />;

  return (
    <div>
      {
        slate.offices.map(office => <BallotCard key={office.key} office={office} onClick={onClick} /> )
      }
      <div className="ballot-cast-vote-button">
        <Button size="lg"
                className="ballot-cast-vote-button"
                color={voteError ? "danger" : "primary"}
                disabled={voteError || noVotes}
                onClick={closeConfirm}
                block>{voteError ? "Check your votes" : "Cast my vote!"}</Button>
      </div>
      <Modal toggle={closeConfirm} isOpen={confirmModal}>
        <ModalHeader toggle={closeConfirm}>Confirm your vote</ModalHeader>
        <ModalBody>
          <h5>Are you ready to cast your votes?</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={castVoteClick}>Yes!</Button>
          <Button color="primary" onClick={closeConfirm}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Ballot;
