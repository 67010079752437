import React from 'react';
import Login from '../Login/Login';
import MissingElectionId from '../MissingElectionId/MissingElectionId';
import ElectionNotFound from "../ElectionNotFound/ElectionNotFound";
import InvalidTime from "../InvalidTime/InvalidTime";
import Vote from '../Vote/Vote';
import Footer from "../Footer/Footer";
import VoteComplete from "../VoteComplete/VoteComplete";
import { useElection } from "../../hooks/useElection";
import './App.css';
import {useSelector} from "react-redux";

const App = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const [election, missingElectionId, electionNotFound, invalidTime, startTime, endTime, tooSoon, tooLate] = useElection(urlParams);

  const token = useSelector(state => state.token);
  const voteComplete = useSelector(state => state.voteComplete);

  let getContent = null;
  if (voteComplete) {
    getContent = <VoteComplete election={election} />
  } else if (missingElectionId) {
    getContent = <MissingElectionId />
  } else if (electionNotFound) {
    getContent = <ElectionNotFound />
  } else if (invalidTime) {
    getContent = <InvalidTime name={election.name}
                              tooSoon={tooSoon}
                              tooLate={tooLate}
                              startTime={startTime}
                              endTime={endTime}/>
  } else if (token) {
    getContent = <Vote election={election} />
  } else if (election) {
    getContent = <Login election={election} />
  }

  return (
    <div>
      {getContent}
      {getContent ? <Footer /> : null}
    </div>
  )
}

export default App;
