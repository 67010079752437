import { Row, Col, Card, CardBody } from 'reactstrap';
import Header from "../Header/Header";
import Ballot from "../Ballot/Ballot";
import './Vote.css';

const Vote = ({ election }) => {

  return (
    <>
      <Card>
        <Header electionName={election.name} />
        <CardBody>
          <Row>
            <Col md={{size:4, offset:4}} sm={{size:6, offset:3}} xs={12}>
          <Ballot election={election} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default Vote;
