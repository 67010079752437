import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { getUnixTime, fromUnixTime, format } from 'date-fns';

export function useElection(queryParams) {

  const [electionId] = useState(queryParams.get('electionId'));
  const [election, setElection] = useState(null);
  const [missingElectionId, setMissingElectionId] = useState(false);
  const [electionNotFound, setElectionNotFound] = useState(false);
  const [invalidTime, setInvalidTime] = useState(false);
  const [tooSoon, setTooSoon] = useState(false);
  const [tooLate, setTooLate] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const TIME_FORMAT = 'EEE, MMM d yyyy h:mm a';

  useEffect(() => {
    if (electionId) {
      let db = firebase.firestore();
      db.collection('electionList')
        .doc(electionId)
        .get()
        .then(doc => {
          if (doc.exists) {
            let election = doc.data();
            setElection(election);
            let now = getUnixTime(new Date());
            setTooSoon(now < election.open);
            setTooLate(election.close < now);
            if ((now < election.open) || (election.close < now)) {
              setInvalidTime(true);
              setStartTime(format(fromUnixTime(election.open), TIME_FORMAT));
              setEndTime(format(fromUnixTime(election.close), TIME_FORMAT));
            }
          } else {
            setElectionNotFound(true);
          }
        })
    } else {
      setMissingElectionId(true);
    }
  }, [electionId])

  return [election, missingElectionId, electionNotFound, invalidTime, startTime, endTime, tooSoon, tooLate]

}
