import React from 'react';
import {Card, CardBody, CardHeader, CardFooter, Col, Row} from "reactstrap";
import Footer from "../Footer/Footer";
import './VoteComplete.css';
import Header from "../Header/Header";

const VoteComplete = ({ election }) => {

  return (
    <Card>
      <CardHeader className="text-center">
        <Header electionName={election.name} />
      </CardHeader>
      <CardBody className="text-center">
        <Row>
          <Col md={12}>
            <h4>Your votes have been recorded!</h4>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default VoteComplete;
