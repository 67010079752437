import as from './actionStrings';
import produce from 'immer';

const reducer = (state, action) => {
  const newState = produce(state, draft => {

    switch (action.type) {
      case as.LOGIN_SUCCESSFUL:
        draft.token = action.payload.token;
        draft.userInfo = action.payload.userInfo;
        break;

      case as.VOTE_COMPLETE:
        draft.voteComplete = true;
        break;

      default:
        break;
    }

  });

  return newState;
}

export default reducer;
