import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import React from "react";

const BallotCard = ({ office, onClick }) => {

  return (
    <Card key={office.key} className="ballot">
      <CardHeader>
        <div className="ballot-office-name">{office.name}</div>
        <div className="ballot-vote-for-number">Vote for {office.voteForNumber}</div>
      </CardHeader>
      <CardBody className={office.tooMany ? "ballot-error" : 'ballot-body'}>
        {
          office.candidates.map(candidate => {
            return (
              <Row key={candidate.key}
                   data-candidate-key={candidate.key}
                   data-office-key={office.key}
                   onClick={onClick}
                   className="ballot-candidate">
                <Col md={2} sm={2} xs={2}>
                  <div className={candidate.state ? "ballot-blot ballot-blot-selected" : "ballot-blot"}></div>
                </Col>
                <Col md={10} sm={10} xs={10}>
                  <div className="ballot-candidate-name">{candidate.firstName} {candidate.lastName}</div>
                </Col>
              </Row>
            )
          })
        }
      </CardBody>
    </Card>
  )
}

export default BallotCard;
