import React from 'react';
import {Card, CardBody, CardHeader, CardFooter, Row, Col} from "reactstrap";
import Footer from "../Footer/Footer";
import './ElectionNotFound.css';

const ElectionNotFound = () => {

  return (
    <Card>
      <CardHeader className="text-center">
        <h1>Election Monkey</h1>
        <h4>Voter login</h4>
      </CardHeader>
      <CardBody className="text-center">
        <Row>
          <Col md={12}>
            <h4>The election ID is invalid</h4>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ElectionNotFound;
